<template>
  <v-row>
    <v-dialog v-model="dialog" fullscreen v-if="items.length" eager>
      <div style="position: relative">
        <v-btn depressed small
               icon @click="dialog = false"
               dark
               large
               style="position: absolute; top: 5px; right: 5px; z-index: 9999">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-carousel
            hide-delimiter-background
            hide-delimiters
            show-arrows-on-hover
            height="100vh"
            v-model="start_idx"
            id="carousel"
            reverse-transition="fade-transition"
            transition="fade-transition"
            class="grey darken-2 gallery-photos"
        >
          <v-carousel-item
              v-for="(slide, i) in items"
              :key="`carousel-${i}`"
          >
            <v-img :src="slide.full_path" contain max-width="95%" max-height="95%"></v-img>
          </v-carousel-item>
        </v-carousel>
      </div>
    </v-dialog>
    <v-col
        v-for="(image, idx) in items"
        :key="idx"
        class="d-flex child-flex"
        cols="12" sm="6" md="3"
    >
      <v-img
          :src="image.full_path"
          aspect-ratio="1"
          position="center"
          class="grey lighten-2"
          @click="openDialog(idx)"
          style="cursor: pointer"
          height="auto"
          width="100%"
      >
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
import siteAPI from "@/utils/axios/personalSite/site"
import {BASE_URL_AXIOS} from "@/utils/axios";
import {check_show_page} from "@/utils/icons";
import {mapGetters} from "vuex";

export default {
  name: "Contacts",
  computed: {
    ...mapGetters({
      site_config: 'getSiteConfig'
    }),
  },
  data() {
    return {
      items: [],
      dialog: false,
      start_idx: 0,
    }
  },
  methods: {
    openDialog(payload) {
      this.start_idx = payload
      this.dialog = true
    }
  },
  created() {
    const check = check_show_page(this.$route.name, this.site_config)
    if (!check) {
      this.$router.push({ name: 'SiteBlocked' })
    }

    const organization_slug = this.$route.params.organization || ''
    if (organization_slug) {
      siteAPI.get_gallery(organization_slug)
          .then(response => response.data)
          .then(data => {
            this.items = data.map((item, index) => {
              return Object.assign({}, item, {idx: index, full_path: `${BASE_URL_AXIOS}/${item.file_path}`})
            })
          })
          .catch(() => {
            this.$router.push({name: 'NotFoundSite'})
          })
    }
  }
}
</script>

<style scoped lang="scss">
.gallery-photos {
  :deep(.v-responsive__content) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.contact-wrapper {
  margin-bottom: 9px;
  padding: 12px;

  .contact-name {
    font-size: 1.06rem;
    text-transform: uppercase;
    font-weight: 500
  }

  .contact-value {
    .contact-value-text {
      font-size: 1.1rem
    }
  }
}
</style>